import styles from "./HomePage.module.css";
import HeroSection from "./HeroSection";
import PillarsSection from "./PillarsSection";
import BusinessCountersSection from "./BusinessCountersSection";

interface Props {
  topBarPadding: string;
}

const HomePage = ({ topBarPadding = "110px" }: Props) => {
  return (
    <div className={styles.homePage} style={{ paddingTop: topBarPadding }}>
      <HeroSection />
      <PillarsSection />
      <BusinessCountersSection />
    </div>
  );
};

export default HomePage;
