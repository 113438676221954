import styles from "./FileDownloadLink.module.css";
import { HiDownload } from "react-icons/hi";

interface Props {
  file: any;
  text: string;
}

const FileDownloadLink = ({ file, text }: Props) => {
  return (
    <a className={styles.fileDownloadLink} href={file} download>
      <div className="icon">
        <HiDownload />
      </div>
      <p>{text}</p>
    </a>
  );
};

export default FileDownloadLink;
