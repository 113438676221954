import styles from "./PillarsSection.module.css";
import PillarCard from "./components/PillarCard";

import {
  ImFolderOpen,
  ImSpinner10,
  ImBullhorn,
  ImFilePicture,
} from "react-icons/im";

const PillarsSection = () => {
  return (
    <section className={styles.pillarsSection}>
      <div className={styles.content}>
        <h2>My Pillars</h2>
        <div className={styles.pillarCards}>
          <PillarCard
            icon={<ImFolderOpen />}
            title={"Project Management"}
            experience={"12"}
            description={
              "From conception to invoicing, along with a proven record in managing on-time delivery and consistent communication, I lean on technology and organization to lead projects from the front row. Like a coach on the court, I'm with my team, leaning into the process. I work across departments and borders to bridge gaps and drive progress forward."
            }
          />

          <PillarCard
            icon={<ImSpinner10 />}
            title={"Business Development"}
            experience={"8"}
            description={
              "I define operational processes that lean on technology and team experience to drive daily tasks forward. I leverage organization and the latest SaaS technology to develop frictionless approaches to common problems. I help integrate new tools, automation & accountability points, alongside reporting & milestone tracking, and data virtualization."
            }
          />
          {/* <PillarCard
            icon={<ImBullhorn />}
            title={"Business Collateral Design"}
            experience={"5"}
            description={
              "I work directly with engineering, business development, and more to create supporting creative collateral. From internal documents to whole go-to-market slide decks. I help communicate your mission and objectives within the brand parameters that tell your story, your way. I have experience with recurring and consistent branding requirements for both internal and external communication. Not just static content, but I have been an Assistant Producer for video material on multiple projects (sizzle videos, subject interviews, and more)."
            }
          /> */}
          <PillarCard
            icon={<ImBullhorn />}
            title={"Marketing Strategy"}
            experience={"10"}
            description={
              "Utilizing a decade of experience in photography, design, UX and social media management, I have mastered blending traiditonal marketing techniques with new-age trends to solidify your digital footprint. I help guide communication with your target audience to communicate your brand within the digital frontier and beyond."
            }
          />
        </div>
      </div>
    </section>
  );
};

export default PillarsSection;
