import styles from "./SixDots.module.css";

interface Props {
  singleRow?: boolean;
}

const SixDots = ({ singleRow }: Props) => {
  if (singleRow)
    return (
      <div className={styles.dots}>
        <div className={styles.row}>
          <div className={styles.dot} />
          <div className={styles.dot} />
          <div className={styles.dot} />
          <div className={styles.dot} />
          <div className={styles.dot} />
          <div className={styles.dot} />
        </div>
      </div>
    );
  return (
    <div className={styles.dots}>
      <div className={styles.row}>
        <div className={styles.dot} />
        <div className={styles.dot} />
      </div>
      <div className={styles.row}>
        <div className={styles.dot} />
        <div className={styles.dot} />
      </div>
      <div className={styles.row}>
        <div className={styles.dot} />
        <div className={styles.dot} />
      </div>
    </div>
  );
};

export default SixDots;
