import { linkedInURL } from "../links";

interface Props {
  lightTheme?: boolean;
}

const LinkedInButton = ({ lightTheme = false }: Props) => {
  const theme = lightTheme ? `btn-outline-bright` : `btn-outline-dark`;

  return (
    <a href={linkedInURL} rel="noreferrer" target="_blank">
      <button className={`btn ${theme}`} type="button">
        Connect on LinkedIn
      </button>
    </a>
  );
};

export default LinkedInButton;
