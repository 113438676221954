import styles from "./Footer.module.css";

import CM from "./assets/CCM-dark.svg";
import Canon from "./assets/canon.png";

import {
  FirstAid,
  GCAcademy,
  NokiaL0,
  NokiaL1,
} from "./assets/certificates/business";
import {
  JavaFundamentals1,
  JavascriptMastery1,
  JavascriptMastery2,
  ResponsiveHTMLCSS,
} from "./assets/certificates/web";
// import Resume from "../assets/Resume.pdf";

import { HiExternalLink } from "react-icons/hi";
import FileDownloadLink from "./components/FileDownloadLink";
import ExternalLink from "./components/ExternalLink";
import HorizontalSeparator from "./components/HorizontalSeparator";
import LinkedInButton from "./components/LinkedInButton";

const Footer = () => {
  return (
    <section className={styles.footer}>
      <div className={`wrapper ${styles.content}`}>
        <div className={styles.top}>
          <div className={styles.footerColumn}>
            <div>
              <img className={styles.logo} src={CM} alt="Corey Mitchell Logo" />
              <p>Corey Mitchell Collins</p>
              <p>Personal Portfolio</p>
            </div>
            {/* <FileDownloadLink file={Resume} text="My Resume" /> */}
            <div>
              <p>Project Manager</p>
              <p>Content Designer</p>
              <p>Web Developer</p>
            </div>
          </div>
          <div className={styles.footerColumn}>
            <h4>Certificates</h4>
            <div>
              <h6>Web Development</h6>
              <FileDownloadLink
                file={JavascriptMastery1}
                text="Javascript Mastery I"
              />
              <FileDownloadLink
                file={JavascriptMastery2}
                text="Javascript Mastery II"
              />
              <FileDownloadLink
                file={ResponsiveHTMLCSS}
                text="Responsive HTML & CSS"
              />
              <FileDownloadLink
                file={JavaFundamentals1}
                text="Java Fundamentals"
              />
            </div>
            <div>
              <h6>Telecommunications</h6>
              <FileDownloadLink file={NokiaL1} text="Nokia L1 Crew Lead" />
              <FileDownloadLink file={NokiaL0} text="Nokia L0 Overview" />
              <FileDownloadLink file={GCAcademy} text="TMO GC Academy" />
            </div>
            <div>
              <h6>Professional</h6>
              <FileDownloadLink file={FirstAid} text="First Aid" />
            </div>
          </div>
          <div className={styles.footerColumn}>
            <h4 style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              My Toolkit
              <HiExternalLink />
            </h4>
            <div>
              <h6>Web Development</h6>
              <ExternalLink
                content={<p>React JS</p>}
                link="https://react.dev/"
              />
              <ExternalLink
                content={<p>Vite JS</p>}
                link="https://vitejs.dev/"
              />
              <ExternalLink
                content={<p>Typescript</p>}
                link="https://www.typescriptlang.org/"
              />
            </div>
            <div>
              <h6>AI Image Generation</h6>
              <ExternalLink
                content={<p>MidJourney</p>}
                link={"https://www.midjourney.com/home"}
              />
              <ExternalLink
                content={<p>StableDiffusion</p>}
                link={
                  "https://github.com/AUTOMATIC1111/stable-diffusion-webui/wiki"
                }
              />
            </div>
            <div>
              <h6>Photo & Design</h6>
              <ExternalLink
                content={<p>Affinity Photo + Designer V2</p>}
                link={"https://affinity.serif.com/en-us/"}
              />
              <ExternalLink
                content={<p>Adobe Lightroom + Photoshop</p>}
                link={"https://www.adobe.com/products/photoshop/"}
              />
              <ExternalLink
                content={<p>Blender 3D</p>}
                link={"https://www.blender.org/"}
              />
              <ExternalLink
                content={<p>Final Cut Pro X</p>}
                link={"https://www.apple.com/final-cut-pro/"}
              />
            </div>
            <p style={{ paddingTop: "10px" }}>
              <ExternalLink
                content={
                  <img
                    className={styles.inlineImage}
                    src={Canon}
                    alt="Canon Camera Logo"
                  />
                }
                link="https://www.usa.canon.com/"
              />
            </p>
          </div>
          <div className={styles.footerColumn}>
            <h4 style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              Past Projects
              <HiExternalLink />
            </h4>
            <div>
              <ExternalLink
                content={<p>Coba Digital LLC</p>}
                link="https://www.coba-digital.com/"
              />
              <ExternalLink
                content={<p>Michigan Whiteout Media</p>}
                link="https://www.michiganwhiteout.com/"
              />
              <ExternalLink
                content={<p>Optomi IT</p>}
                link="https://optomi.com/"
              />
              <ExternalLink
                content={<p>Dayton LWC</p>}
                link="https://www.dlwc.org/"
              />
              <ExternalLink
                content={<p>Wellspring CCMI</p>}
                link="https://www.wellspringcc.org/"
              />
              <ExternalLink
                content={<p>Spitz Electric</p>}
                link="https://spitzelectric.com/"
              />
              <ExternalLink
                content={<p>Heights Tower Service</p>}
                link="https://heightstower.com/"
              />
              <ExternalLink
                content={<p>STRATACACHE</p>}
                link="https://stratacache.com/"
              />
            </div>
          </div>
        </div>
        <HorizontalSeparator />
        <div className={styles.bottom}>
          <LinkedInButton />
          <div>
            <p>&copy; Copyright 2024 Corey Mitchell Collins.</p>
            <p>All rights reserved.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
