import "./styles/App.css";
import "./styles/Colors.css";
import "./styles/Fonts.css";

import TopBar from "./TopBar";
import Footer from "./Footer";
import { HomePage } from "./pages";
import HorizontalSeparator from "./components/HorizontalSeparator";

function App() {
  const topBarHeight = "110px";

  return (
    <div className="App">
      <div className="stick-to-top">
        <TopBar height={topBarHeight} />
      </div>
      <HomePage topBarPadding={topBarHeight} />
      <HorizontalSeparator />
      <Footer />
    </div>
  );
}

export default App;
