import LinkedInButton from "../../components/LinkedInButton";
import styles from "./HeroSection.module.css";

import CoreyFull from "./assets/corey-full.webp";
import CoreyMobile from "./assets/corey-mobile.webp";

const HeroSection = () => {
  return (
    <div className={styles.heroSection}>
      <div className={`wrapper ${styles.content}`}>
        <div className={styles.leftSideFull}>
          <img
            className={styles.corey}
            src={CoreyFull}
            alt="Corey Collins - Profile"
          />
        </div>
        <div className={styles.leftSideMobile}>
          <img
            className={styles.corey}
            src={CoreyMobile}
            alt="Corey Collins - Profile"
          />
        </div>
        <div className={styles.rightSide}>
          <div>
            <h1>
              I'm Corey<span>.</span>
            </h1>
            <h4>Project Management. Operations. Development. Design.</h4>
          </div>
          <p>
            I use dynamic experience in <span>project management</span> &{" "}
            <span>business development</span> + <span>creative design</span> to
            see the bigger picture when it comes to operations and marketing
            strategies. At the heart of my approach is a love for capturing the
            passion you feel about your business. I transform business passion
            into straight lines and process that just makes sense.
          </p>
          <p>
            I create strategies that foster collaboration and inspire teams to
            capture the essense of your business in ways that resonate across
            your organization.
          </p>
          <div className={styles.bottomRow}>
            <LinkedInButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
