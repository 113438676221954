import { useEffect, useState, useRef } from "react";
import styles from "./CounterAnimation.module.css";
import { formatNumberAsAbbreviated } from "../../../utils/StringFormatter";

interface Props {
  number: number;
  isUSD: boolean;
  textUpper: string;
  textLower: string;
}

const CounterAnimation = ({ number, isUSD, textUpper, textLower }: Props) => {
  const [counted, setCounted] = useState(false);
  const [countValue, setCountValue] = useState(0);
  const counterRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !counted) {
            let start = 0;
            const increment = number / 20; // Change number / 60 to adjust speed
            const timer = setInterval(() => {
              start += increment;
              setCountValue(Math.min(Math.ceil(start), number));
              if (start >= number) {
                clearInterval(timer);
                setCounted(true);
              }
            }, 100); // Interval for smooth animation, adjust as needed

            // Trigger CSS change when counting starts
            if (counterRef.current) {
              counterRef.current.style.transform = "translateY(0px)";
              counterRef.current.style.opacity = "1";
            }
          }
        });
      },
      { threshold: 1 } // 50% visibility threshold
    );

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, [counted, number]);

  return (
    <div className={styles.counter} ref={counterRef}>
      <h3>{textUpper}</h3>
      <h2>
        {isUSD && "$"}
        {formatNumberAsAbbreviated(countValue)}
      </h2>
      <p>{textLower}</p>
    </div>
  );
};

export default CounterAnimation;
