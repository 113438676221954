const HorizontalSeparator = () => {
  return (
    <div
      style={{
        height: "1px",
        background:
          "linear-gradient(to right, transparent, var(--gray-faded), transparent)",
        width: "100%",
      }}
    />
  );
};

export default HorizontalSeparator;
