import styles from "./PillarCard.module.css";

interface Props {
  icon: any;
  title: string;
  experience: string;
  description: string;
}

const PillarCard = ({ icon, title, experience, description }: Props) => {
  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <div className={styles.title}>
          <div className={styles.icon}>{icon}</div>
          <h6>{title}</h6>
        </div>
        <div className={styles.experience}>{experience} yrs</div>
      </div>
      <p>{description}</p>
      <div className={styles.pill} />
    </div>
  );
};

export default PillarCard;
