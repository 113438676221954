export function formatNumberAsAbbreviated(num: number): string {
  const billion = 1e9;
  const million = 1e6;
  const thousand = 1e3;

  if (num >= billion) {
    return Math.floor(num / billion) + "B";
  } else if (num >= million) {
    return Math.floor(num / million) + "M";
  } else if (num >= thousand) {
    return Math.floor(num / thousand) + "K";
  } else {
    return num.toString();
  }
}
