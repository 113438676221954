import styles from "./TopBar.module.css";
import Logo from "./assets/CCM-dark.svg";
import LinkedInButton from "./components/LinkedInButton";

interface Props {
  height?: string;
}

const TopBar = ({ height }: Props) => {
  return (
    <section className={styles.topBar} style={{ height: height }}>
      <div className={`wrapper ${styles.content}`}>
        <div className={styles.logo}>
          <img src={Logo} alt="Corey Mitchell Logo" />
        </div>
        <LinkedInButton />
      </div>
    </section>
  );
};

export default TopBar;
