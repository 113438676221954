import SixDots from "../../components/SixDots";
import styles from "./BusinessCountersSection.module.css";
import CounterAnimation from "./components/CounterAnimation";

const BusinessCountersSection = () => {
  return (
    <section className={styles.businessCountersSection}>
      <div className={`wrapper ${styles.content}`}>
        <CounterAnimation
          number={100000000}
          isUSD={true}
          textUpper={"Over"}
          textLower={"in managed revenue"}
        />
        <div className={styles.dots}>
          <SixDots />
        </div>
        <CounterAnimation
          number={5000}
          isUSD={false}
          textUpper={"Over"}
          textLower={"creative assets developed"}
        />
        <div className={styles.dots} style={{ rotate: "180deg" }}>
          <SixDots />
        </div>
        <CounterAnimation
          number={5}
          isUSD={false}
          textUpper={"Across"}
          textLower={"business sectors"}
        />
      </div>
    </section>
  );
};

export default BusinessCountersSection;
