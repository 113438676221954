interface Props {
  content: any;
  link: string;
}

const ExternalLink = ({ content, link }: Props) => {
  return (
    <a href={link} target="_blank" rel="noreferrer">
      {content}
    </a>
  );
};

export default ExternalLink;
